import { THEME } from "constants/AuthConstant";
import PermissionManager from "utils/class/PermissionManager";

/**
 * Retrieves the current theme from local storage.
 * If no theme is found, sets the default theme to "light" and returns it.
 *
 * @returns {string} - The current theme ("light" or "dark").
 */
export const getStoredTheme = () => {
	// Retrieve the theme from local storage
	const storedTheme = PermissionManager.getItem(THEME) || "dark";

	// If no theme is found, set the default theme to "light"
	if (!PermissionManager.getItem(THEME)) {
		PermissionManager.setItem(THEME, "dark");
	}

	// Return the stored or default theme
	return storedTheme;
};
