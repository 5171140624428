import axiosHttp from "services/axiosHttp";
import { REWARD_BASE_URL } from "configs/AppConfig";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Action for updating a tier
export const updateTier = createAsyncThunk("updateTier", async ({ channelId, tierId, tierData }, { rejectWithValue }) => {
	try {
		const response = await axiosHttp.put(`${REWARD_BASE_URL}channel/${channelId}/tier/${tierId}`, tierData);
		return response.data;
	} catch (error) {
		// Handle errors
		return rejectWithValue(error.response.data);
	}
});

const UpdateTierSlice = createSlice({
	name: "UpdateTierSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
	},
	extraReducers: (builder) => {
		builder
			.addCase(updateTier.pending, (state) => {
				state.isLoading = true;
				state.error = null;
			})
			.addCase(updateTier.fulfilled, (state, action) => {
				state.isLoading = false;
				state.data = action.payload;
			})
			.addCase(updateTier.rejected, (state, action) => {
				state.isLoading = false;
				state.error = action.payload || "An error occurred while updating the tier.";
			});
	},
});

export default UpdateTierSlice.reducer;
