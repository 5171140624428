import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { REWARD_BASE_URL } from "configs/AppConfig";
import axiosHttp from "services/axiosHttp";

//CREATE ACTION
export const createRewardUser = createAsyncThunk("createRewardUser", async ({values,user_channel}, { rejectWithValue }) => {
	try {
		const response = await axiosHttp.post(`${REWARD_BASE_URL}backOffice/user/register?channel_id=${user_channel}`, values);

		return response.data;
	} catch (err) {
		return rejectWithValue(err.response.data);
	}
});

const RewardUserCreateSlice = createSlice({
	name: "RewardUserCreateSlice",
	initialState: {
		isLoading: false,
		isError: false,
		error: null,
		data: null,
	},
	reducers: {
		resetStateRewardUserCreateSlice: (state) => {
		  // Reset the state to its initial values
		  return  {
			isLoading: false,
			isError: false,
			error: null,
			data: null,
		};
		},
	},
	extraReducers: (builder) => {
		builder.addCase(createRewardUser.pending, (state) => {
			state.isLoading = true;
			state.isError = false;
			state.error = null;
		});

		builder.addCase(createRewardUser.fulfilled, (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.data = action.payload;
			state.error = null;
		});

		builder.addCase(createRewardUser.rejected, (state, action) => {
			state.isLoading = false;
			state.isError = true;
			state.error = action.payload;
		});
	},
});

// Export the reset action to use it in components
export const { resetStateRewardUserCreateSlice } = RewardUserCreateSlice.actions;


export default RewardUserCreateSlice.reducer;
