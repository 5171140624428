import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { REWARD_BASE_URL } from "configs/AppConfig";
import axiosHttp from "services/axiosHttp";

//CREATE ACTION
export const fetchRewardSupplierById = createAsyncThunk("fetchRewardSupplierById", async (values, { rejectWithValue }) => {
	try {
		const response = await axiosHttp.get(`${REWARD_BASE_URL}channel/${values.channel}/supplier/${values.id}`);

		return response.data;
	} catch (err) {
		if (!err.response) {
			throw err;
		}
		return rejectWithValue(err.response.data);
	}
});

const RewardSupplierSlice = createSlice({
	name: "RewardSupplierSlice",
	initialState: {
		isLoading: false,
		isError: false,
		error: null,
		data: null,
	},

	reducers: {
		resetRewardSupplierState: (state) => {
			state.isLoading = false;
			state.isError = false;
			state.error = null;
			state.data = null;
		},
	},

	extraReducers: (builder) => {
		builder.addCase(fetchRewardSupplierById.pending, (state) => {
			state.isLoading = true;
			state.isError = false;
		});

		builder.addCase(fetchRewardSupplierById.fulfilled, (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.data = action.payload;
		});

		builder.addCase(fetchRewardSupplierById.rejected, (state, action) => {
			state.isLoading = false;
			state.isError = true;
			state.error = action.payload;
		});
	},
});

export const { resetRewardSupplierState } = RewardSupplierSlice.actions;

export default RewardSupplierSlice.reducer;
