import axiosHttp from "services/axiosHttp";
import { REWARD_BASE_URL } from "configs/AppConfig";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchRewardUserTypes = createAsyncThunk("fetchRewardUserTypes", async (channel_uuid) => {
	const response = await axiosHttp.get(`${REWARD_BASE_URL}channel/${channel_uuid}/user_types`);
	return response.data.data;
});

const RewardUserTypesSlice = createSlice({
	name: "RewardUserTypesSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
		page: 1,
		total: 0,
	},

	extraReducers: (builder) => {
		builder.addCase(fetchRewardUserTypes.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(fetchRewardUserTypes.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
			state.page = action.payload.currentPage;
			state.total = action.payload.total;
		});
		builder.addCase(fetchRewardUserTypes.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export default RewardUserTypesSlice.reducer;
