import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { REWARD_BASE_URL } from "configs/AppConfig";
import axiosHttp from "services/axiosHttp";

//CREATE ACTION
export const fetchRewardSupplierList = createAsyncThunk("fetchRewardSupplierList", async (values, { rejectWithValue }) => {
	try {
		const response = await axiosHttp.get(`${REWARD_BASE_URL}channel/${values.channel}/supplier`, {...values,channel:undefined});

		return response.data;
	} catch (err) {
		if (!err.response) {
			throw err;
		}
		return rejectWithValue(err.response.data);
	}
});

const RewardSupplierListSlice = createSlice({
	name: "RewardSupplierListSlice",
	initialState: {
		isLoading: false,
		isError: false,
		error: null,
		data: null,
	},

	reducers: {
		resetRewardSupplierListState: (state) => {
			state.isLoading = false;
			state.isError = false;
			state.error = null;
			state.data = null;
		},
	},

	extraReducers: (builder) => {
		builder.addCase(fetchRewardSupplierList.pending, (state) => {
			state.isLoading = true;
			state.isError = false;
		});

		builder.addCase(fetchRewardSupplierList.fulfilled, (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.data = action.payload;
		});

		builder.addCase(fetchRewardSupplierList.rejected, (state, action) => {
			state.isLoading = false;
			state.isError = true;
			state.error = action.payload;
		});
	},
});

export const { resetRewardSupplierListState } = RewardSupplierListSlice.actions;

export default RewardSupplierListSlice.reducer;
