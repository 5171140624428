import axiosHttp from "services/axiosHttp";
import { REWARD_BASE_URL } from "configs/AppConfig";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchRewardAllItemsList = createAsyncThunk("fetchRewardAllItemsList", async (user_channel) => {
	const response = await axiosHttp.get(`${REWARD_BASE_URL}product/items?channel_uuid=${user_channel}`);
	return response.data.data;
});

const RewardAllItemListSlice = createSlice({
	name: "RewardAllItemListSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
	},

	extraReducers: (builder) => {
		builder.addCase(fetchRewardAllItemsList.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(fetchRewardAllItemsList.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
		});
		builder.addCase(fetchRewardAllItemsList.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export default RewardAllItemListSlice.reducer;
