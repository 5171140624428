import React from "react";
import { APP_PREFIX_PATH } from "configs/AppConfig";

export const rewardRoutes = [
	{
		key: "reward-user-list",
		exact: true,
		path: `${APP_PREFIX_PATH}/reward/users`,
		component: React.lazy(() => import("views/reward-views/users/pages/RewardUserList")),
	},
	{
		key: "category-list",
		exact: true,
		path: `${APP_PREFIX_PATH}/reward/category-list`,
		component: React.lazy(() => import("views/reward-views/categories/pages/RewardCategoriesList")),
	},
	{
		key: "reward-product-list",
		exact: true,
		path: `${APP_PREFIX_PATH}/reward/product-list`,
		component: React.lazy(() => import("views/reward-views/products/pages/RewardProductList")),
	},
	{
		key: "reward-product-create",
		exact: true,
		path: `${APP_PREFIX_PATH}/reward/product`,
		component: React.lazy(() => import("views/reward-views/products/pages/RewardProductCreate")),
	},
	{
		key: "reward-product-detail",
		exact: true,
		path: `${APP_PREFIX_PATH}/reward/product/:id`,
		component: React.lazy(() => import("views/reward-views/products/pages/RewardProductEdit")),
	},
	{
		key: "reward-product-upload",
		exact: true,
		path: `${APP_PREFIX_PATH}/reward/product/upload`,
		component: React.lazy(() => import("views/reward-views/products/pages/RewardProductBulkUpload")),
	},
	{
		key: "reward-pricebook-list",
		exact: true,
		path: `${APP_PREFIX_PATH}/reward/pricebooks`,
		component: React.lazy(() => import("views/reward-views/pricebook/pages/PriceBookList")),
	},
	{
		key: "reward-pricebook-item-upload",
		exact: true,
		path: `${APP_PREFIX_PATH}/reward/pricebook/:id/upload`,
		component: React.lazy(() => import("views/reward-views/pricebook/pages/PriceBookItemUpload")),
	},
	{
		key: "reward-pricebook-create",
		exact: true,
		path: `${APP_PREFIX_PATH}/reward/pricebook/create`,
		component: React.lazy(() => import("views/reward-views/pricebook/pages/PriceBookCreate")),
	},
	{
		key: "reward-pricebook-detail",
		exact: true,
		path: `${APP_PREFIX_PATH}/reward/pricebook/:id`,
		component: React.lazy(() => import("views/reward-views/pricebook/pages/PriceBookInfo")),
	},
	{
		key: "reward-global-channel",
		exact: true,
		path: `${APP_PREFIX_PATH}/reward/global-channels`,
		component: React.lazy(() => import("views/reward-views/channels/pages/GlobalChannelList")),
	},
	{
		key: "reward-channel-config",
		exact: true,
		path: `${APP_PREFIX_PATH}/reward/channel`,
		component: React.lazy(() => import("views/reward-views/channels/pages/ChannelConfig")),
	},
	{
		key: "reward-inventory-list",
		exact: true,
		path: `${APP_PREFIX_PATH}/reward/inventory/list`,
		component: React.lazy(() => import("views/reward-views/inventory/pages/InventoryList")),
	},
	{
		key: "reward-inventory-upload",
		exact: true,
		path: `${APP_PREFIX_PATH}/reward/inventory/upload`,
		component: React.lazy(() => import("views/reward-views/inventory/pages/InventoryBulkUpload")),
	},
	{
		key: "reward-order-list",
		exact: true,
		path: `${APP_PREFIX_PATH}/reward/order/list`,
		component: React.lazy(() => import("views/reward-views/orders/pages/OrderList")),
	},
	{
		key: "reward-HR-order-list",
		exact: true,
		path: `${APP_PREFIX_PATH}/reward/order/hr/list`,
		component: React.lazy(() => import("views/reward-views/orders/pages/HROrderList")),
	},
	{
		key: "reward-order-detail",
		exact: true,
		path: `${APP_PREFIX_PATH}/reward/order/detail/:id`,
		component: React.lazy(() => import("views/reward-views/orders/pages/OrderDetail")),
	},
	{
		key: "reward-media-library",
		exact: true,
		path: `${APP_PREFIX_PATH}/reward/media/library`,
		component: React.lazy(() => import("views/reward-views/media/pages/MediaList")),
	},
	{
		key: "reward-pos",
		exact: true,
		path: `${APP_PREFIX_PATH}/reward/pos`,
		component: React.lazy(() => import("views/reward-views/pos/pages/PosInterface")),
	},
	{
		key: "supplier-list",
		exact: true,
		path: `${APP_PREFIX_PATH}/reward/suppliers/list`,
		component: React.lazy(() => import("views/reward-views/supplier/pages/SupplierList")),
	},
	{
		key: "supplier-edit",
		exact: true,
		path: `${APP_PREFIX_PATH}/reward/supplier/edit/:id`,
		component: React.lazy(() => import("views/reward-views/supplier/pages/SupplierEdit")),
	},
	{
		key: "supplier-add",
		exact: true,
		path: `${APP_PREFIX_PATH}/reward/supplier/add`,
		component: React.lazy(() => import("views/reward-views/supplier/pages/SupplierAdd")),
	},
	{
		key: "po-list",
		exact: true,
		path: `${APP_PREFIX_PATH}/reward/po/list`,
		component: React.lazy(() => import("views/reward-views/po/pages/POList")),
	},
	{
		key: "po-edit",
		exact: true,
		path: `${APP_PREFIX_PATH}/reward/po/edit/:id`,
		component: React.lazy(() => import("views/reward-views/po/pages/POEdit")),
	},
	{
		key: "po-add",
		exact: true,
		path: `${APP_PREFIX_PATH}/reward/po/add`,
		component: React.lazy(() => import("views/reward-views/po/pages/POAdd")),
	},
];
