import axiosHttp from "services/axiosHttp";
import { REWARD_BASE_URL } from "configs/AppConfig";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchRewardProductList = createAsyncThunk("fetchRewardProductList", async ({ filters, page, limit }, { rejectWithValue }) => {
	const params = new URLSearchParams({
		page: page,
		limit: limit,
		keyword: filters?.keyword ?? "",
		status_id: filters?.status_id ?? "",
		type_id: filters?.type_id ?? "",
		channel_uuid: filters?.channel_uuid,
	});

	try {
		const response = await axiosHttp.get(`${REWARD_BASE_URL}products?${params}`);
		return response.data.data;
	} catch (error) {
		return rejectWithValue(error.response?.data?.message || "Something went wrong");
	}
});

const RewardProductListSlice = createSlice({
	name: "RewardProductListSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
		page: 1,
		total: 0,
	},

	extraReducers: (builder) => {
		builder.addCase(fetchRewardProductList.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(fetchRewardProductList.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
			state.page = action.payload.currentPage;
			state.total = action.payload.total;
		});

		builder.addCase(fetchRewardProductList.rejected, (state, action) => {
			state.isLoading = false;

			if (action.error.message) {
				state.error = action.error.message;
			} else {
				state.error = "Unknown error occurred";
			}
		});
	},
});

export default RewardProductListSlice.reducer;
