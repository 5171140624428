import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { REWARD_BASE_URL } from "configs/AppConfig";
import axiosHttp from "services/axiosHttp";

//CREATE ACTION
export const editRewardProductItems = createAsyncThunk("editRewardProductItems", async ({ productId, itemId, data }, { rejectWithValue }) => {
	try {
		const response = await axiosHttp.put(`${REWARD_BASE_URL}product/${productId}/item/${itemId}`, data);

		return response.data;
	} catch (err) {
		if (!err.response) {
			throw err;
		}
		return rejectWithValue(err.response.data);
	}
});

const RewardProductItemEditSlice = createSlice({
	name: "RewardProductItemEditSlice",
	initialState: {
		isLoading: false,
		isError: false,
		error: null,
		data: null,
	},

	extraReducers: (builder) => {
		builder.addCase(editRewardProductItems.pending, (state) => {
			state.isLoading = true;
			state.isError = false;
		});

		builder.addCase(editRewardProductItems.fulfilled, (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.data = action.payload;
		});

		builder.addCase(editRewardProductItems.rejected, (state, action) => {
			state.isLoading = false;
			state.isError = true;
			state.error = action.payload;
		});
	},
});

export default RewardProductItemEditSlice.reducer;
