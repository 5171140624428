import axiosHttp from "services/axiosHttp";
import { REWARD_BASE_URL } from "configs/AppConfig";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchChannelList = createAsyncThunk("fetchChannelList", async () => {
	const response = await axiosHttp.get(`${REWARD_BASE_URL}channels`);
	return response.data.data;
});

const ChannelListSlice = createSlice({
	name: "ChannelListSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
		page: 1,
		total: 0,
	},

	extraReducers: (builder) => {
		builder.addCase(fetchChannelList.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(fetchChannelList.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
		});
		builder.addCase(fetchChannelList.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export default ChannelListSlice.reducer;
