import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE, DIR_LTR } from "constants/ThemeConstant";
import { getStoredTheme } from "store/slices/themeConfig";

export const APP_NAME = "iOrel";

//LIVE
export const API_BASE_URL = "https://58vnsnfkl0.execute-api.ap-southeast-1.amazonaws.com/iorel/api/v1/";

//DEV
// export const API_BASE_URL = "https://os93gdj3v3.execute-api.ap-southeast-1.amazonaws.com/dev/iorel/api/v1/";

// REWARD LIVE
export const REWARD_BASE_URL = "https://58vnsnfkl0.execute-api.ap-southeast-1.amazonaws.com/sales-backoffice/api/v1/backoffice/";

// REWARD DEV
// export const REWARD_BASE_URL = "https://os93gdj3v3.execute-api.ap-southeast-1.amazonaws.com/dev/sales-backoffice/api/v1/backoffice/";

// AWS_IMAGE_BUCKET_URL LIVE
export const AWS_IMAGE_BUCKET_URL = "https://tj2yqcmtmm.ap-southeast-1.awsapprunner.com/";

// AWS_IMAGE_BUCKET_URL DEV
// export const AWS_IMAGE_BUCKET_URL = "https://qx3n2pyxed.ap-southeast-1.awsapprunner.com/";

export const APP_PREFIX_PATH = "/app";
export const AUTH_PREFIX_PATH = "/auth";
export const REDIRECT_URL_KEY = "redirect";
export const AUTHENTICATED_ENTRY = `${APP_PREFIX_PATH}/dashboards/default`;
export const UNAUTHENTICATED_ENTRY = "/login";
export const FIRSTTIME_RESET_ENTRY = "/first-reset";
export const IMAGE_UPLOAD_URL = "https://9amzq73qyt.us-east-2.awsapprunner.com/api/v1/iorel/upload/create";

export const THEME_CONFIG = {
	navCollapsed: false,
	sideNavTheme: SIDE_NAV_LIGHT,
	locale: "en",
	navType: NAV_TYPE_SIDE,
	mobileNav: false,
	currentTheme: getStoredTheme(),
	direction: DIR_LTR,
	blankLayout: false,
};
