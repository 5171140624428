import axiosHttp from "services/axiosHttp";
import { REWARD_BASE_URL } from "configs/AppConfig";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchRewardProductVariant = createAsyncThunk("fetchRewardProductVariant", async (id) => {
	const response = await axiosHttp.get(`${REWARD_BASE_URL}product/${id}/variant`);
	return response.data.data;
});

const RewardProductVariantSlice = createSlice({
	name: "RewardProductVariantSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
	},

	extraReducers: (builder) => {
		builder.addCase(fetchRewardProductVariant.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(fetchRewardProductVariant.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
		});
		builder.addCase(fetchRewardProductVariant.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export default RewardProductVariantSlice.reducer;
