import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { REWARD_BASE_URL } from "configs/AppConfig";
import axiosHttp from "services/axiosHttp";

//CREATE ACTION
export const editRewardPricebook = createAsyncThunk("editRewardPricebook", async ({ id, data }, { rejectWithValue }) => {
	try {
		const response = await axiosHttp.put(`${REWARD_BASE_URL}pricebook/${id}`, data);
		return response.data;
	} catch (err) {
		if (!err.response) {
			throw err;
		}
		return rejectWithValue(err.response.data);
	}
});

const RewardPricebookEditSlice = createSlice({
	name: "RewardPricebookEditSlice",
	initialState: {
		isLoading: false,
		isError: false,
		error: null,
		data: null,
	},

	extraReducers: (builder) => {
		builder.addCase(editRewardPricebook.pending, (state, action) => {
			state.isLoading = true;
			state.isError = false;
		});

		builder.addCase(editRewardPricebook.fulfilled, (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.data = action.payload;
		});

		builder.addCase(editRewardPricebook.rejected, (state, action) => {
			state.isLoading = false;
			state.isError = true;
			state.error = action.payload;
		});
	},
});

export default RewardPricebookEditSlice.reducer;
