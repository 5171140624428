import axiosHttp from "services/axiosHttp";
import { REWARD_BASE_URL } from "configs/AppConfig";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchCategoryTree = createAsyncThunk("fetchCategoryTree", async ({ filters } = {}) => {
	const params = new URLSearchParams({
		channel_uuid: filters?.channel_uuid ?? "",
		keyword: filters?.keyword ?? "",
	});
	const response = await axiosHttp.get(`${REWARD_BASE_URL}categories?${params}`);
	return response.data.data;
});

const CategoryTreeSlice = createSlice({
	name: "CategoryTreeSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
	},

	extraReducers: (builder) => {
		builder.addCase(fetchCategoryTree.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(fetchCategoryTree.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
		});
		builder.addCase(fetchCategoryTree.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export default CategoryTreeSlice.reducer;
