import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { REWARD_BASE_URL } from "configs/AppConfig";
import axiosHttp from "services/axiosHttp";

//CREATE ACTION
export const rewardSupplierEdit = createAsyncThunk("rewardSupplierEdit", async (values, { rejectWithValue }) => {
	try {
		const response = await axiosHttp.patch(`${REWARD_BASE_URL}channel/${values.channel}/supplier/${values?.id}`, {...values,channel:undefined,id:undefined});

		return response.data;
	} catch (err) {
		if (!err.response) {
			throw err;
		}
		return rejectWithValue(err.response.data);
	}
});

const RewardSupplierEditSlice = createSlice({
	name: "RewardSupplierEditSlice",
	initialState: {
		isLoading: false,
		isError: false,
		error: null,
		data: null,
	},

	reducers: {
		resetRewardSupplierEditState: (state) => {
			state.isLoading = false;
			state.isError = false;
			state.error = null;
			state.data = null;
		},
	},

	extraReducers: (builder) => {
		builder.addCase(rewardSupplierEdit.pending, (state) => {
			state.isLoading = true;
			state.isError = false;
		});

		builder.addCase(rewardSupplierEdit.fulfilled, (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.data = action.payload;
		});

		builder.addCase(rewardSupplierEdit.rejected, (state, action) => {
			state.isLoading = false;
			state.isError = true;
			state.error = action.payload;
		});
	},
});

export const { resetRewardSupplierEditState } = RewardSupplierEditSlice.actions;

export default RewardSupplierEditSlice.reducer;
