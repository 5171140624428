import axiosHttp from "services/axiosHttp";
import { REWARD_BASE_URL } from "configs/AppConfig";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchRewardProductStatus = createAsyncThunk("fetchRewardProductStatus", async (_, { rejectWithValue }) => {
	try {
		const response = await axiosHttp.get(`${REWARD_BASE_URL}statuses?keyword=product`);
		return response.data.data;
	} catch (error) {
		return rejectWithValue(error.response?.data?.message || "Something went wrong while fetching statuses");
	}
});

const RewardProductStatusSlice = createSlice({
	name: "RewardProductStatusSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
		page: 1,
		total: 0,
	},

	extraReducers: (builder) => {
		builder.addCase(fetchRewardProductStatus.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(fetchRewardProductStatus.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
			state.page = action.payload.currentPage || 1;
			state.total = action.payload.total || 0;
		});

		builder.addCase(fetchRewardProductStatus.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload || action.error.message || "Unknown error occurred";
		});
	},
});

export default RewardProductStatusSlice.reducer;
