import axiosHttp from "services/axiosHttp";
import { REWARD_BASE_URL } from "configs/AppConfig";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";

// Action for fetching inventory summary from the new endpoint
export const fetchPriceBookItemUpload = createAsyncThunk(
  "fetchPriceBookItemUpload",
  async ({ id,file }, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const response = await axiosHttp.post(`${REWARD_BASE_URL}pricebook/${id}/upload`,formData);
      message.success("Prices uploaded successfully!");
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || "Something went wrong");
    }
  }
);

const RewardPriceBookItemUploadSlice = createSlice({
  name: "RewardPriceBookItemUploadSlice",
  initialState: {
    isLoading: false,
    data: null,
    error: null,
  },

  reducers: {
		resetRewardPriceBookItemUploadSlice: (state) => {
			state.isLoading = false;
			state.isError = false;
			state.error = null;
			state.data = null;
		},
	},

  extraReducers: (builder) => {
    builder.addCase(fetchPriceBookItemUpload.pending, (state) => {
      state.isLoading = true;
      state.error = null; // Clear any previous error on new request
    });

    builder.addCase(fetchPriceBookItemUpload.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload; // Adjust based on response structure
    });

    builder.addCase(fetchPriceBookItemUpload.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload || "Unknown error occurred";
    });
  },
});


export default RewardPriceBookItemUploadSlice.reducer;
export const { resetRewardPriceBookItemUploadSlice } = RewardPriceBookItemUploadSlice.actions;
