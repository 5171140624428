import axiosHttp from "services/axiosHttp";
import { REWARD_BASE_URL } from "configs/AppConfig";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Action for fetching all tiers for a specific channel
export const fetchPaymentMethodsSlice = createAsyncThunk("FetchPaymentMethodsSlice", async (channelId, { rejectWithValue }) => {
	try {
		const response = await axiosHttp.get(`${REWARD_BASE_URL}channel/${channelId}/payment-methods/`);
		return response.data.data;
	} catch (error) {
		// Handle errors
		return rejectWithValue(error.response.data);
	}
});

const FetchPaymentMethodsSlice = createSlice({
	name: "FetchPaymentMethodsSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
	},
	reducers: {
		resetFetchPaymentMethodsSlice: (state) => {
		  // Reset the state to its initial values
		  return  {
				isLoading: false,
				data: null,
				error: null,
			};
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchPaymentMethodsSlice.pending, (state) => {
				state.isLoading = true;
				state.error = null;
			})
			.addCase(fetchPaymentMethodsSlice.fulfilled, (state, action) => {
				state.isLoading = false;
				state.data = action.payload;
			})
			.addCase(fetchPaymentMethodsSlice.rejected, (state, action) => {
				state.isLoading = false;
				state.error = action.payload || "An error occurred while fetching tiers for the channel.";
			});
	},
});

// Export the reset action to use it in components
export const { resetFetchPaymentMethodsSlice } = FetchPaymentMethodsSlice.actions;

export default FetchPaymentMethodsSlice.reducer;
