import axiosHttp from "services/axiosHttp";
import { REWARD_BASE_URL } from "configs/AppConfig";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Action
export const createTier = createAsyncThunk("createTier", async ({tierData,channelId}, { rejectWithValue }) => {
	try {
		const response = await axiosHttp.post(`${REWARD_BASE_URL}channel/${channelId}/tier`, tierData);
		return response.data;
	} catch (error) {
		// Handle errors
		return rejectWithValue(error.response.data);
	}
});

const CreateTierSlice = createSlice({
	name: "CreateTierSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
	},

	extraReducers: (builder) => {
		builder
			.addCase(createTier.pending, (state) => {
				state.isLoading = true;
				state.error = null; // Clear any previous errors
			})
			.addCase(createTier.fulfilled, (state, action) => {
				state.isLoading = false;
				state.data = action.payload;
			})
			.addCase(createTier.rejected, (state, action) => {
				state.isLoading = false;
				state.error = action.payload || "An error occurred while creating the tier.";
			});
	},
});

export default CreateTierSlice.reducer;
