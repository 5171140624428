import axiosHttp from "services/axiosHttp";
import { REWARD_BASE_URL } from "configs/AppConfig";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchRewardPriceBookStatus = createAsyncThunk("fetchRewardPriceBookStatus", async () => {
	const response = await axiosHttp.get(`${REWARD_BASE_URL}statuses?keyword=price_book`);
	return response.data.data;
});

const RewardPriceBookStatusSlice = createSlice({
	name: "RewardPriceBookStatusSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
		page: 1,
		total: 0,
	},

	extraReducers: (builder) => {
		builder.addCase(fetchRewardPriceBookStatus.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(fetchRewardPriceBookStatus.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
			state.page = action.payload.currentPage;
			state.total = action.payload.total;
		});
		builder.addCase(fetchRewardPriceBookStatus.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export default RewardPriceBookStatusSlice.reducer;
