import axiosHttp from "services/axiosHttp";
import { REWARD_BASE_URL } from "configs/AppConfig";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchRewardProductTypes = createAsyncThunk("fetchRewardProductTypes", async () => {
	const response = await axiosHttp.get(`${REWARD_BASE_URL}common/categories?category_type=product_variations`);
	return response.data.data;
});

const RewardProductTypesSlice = createSlice({
	name: "RewardProductTypesSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
	},

	extraReducers: (builder) => {
		builder.addCase(fetchRewardProductTypes.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(fetchRewardProductTypes.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
		});
		builder.addCase(fetchRewardProductTypes.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export default RewardProductTypesSlice.reducer;
