import axiosHttp from "services/axiosHttp";
import { REWARD_BASE_URL } from "configs/AppConfig";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchRewardOrderInfo = createAsyncThunk("fetchRewardOrderInfo", async ({ id, channel_id }) => {
	const response = await axiosHttp.get(`${REWARD_BASE_URL}order/${id}/details?channel_id=${channel_id}`);
	return response.data.data;
});

const RewardOrderInfoSlice = createSlice({
	name: "RewardOrderInfoSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
	},

	extraReducers: (builder) => {
		builder.addCase(fetchRewardOrderInfo.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(fetchRewardOrderInfo.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
		});
		builder.addCase(fetchRewardOrderInfo.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export default RewardOrderInfoSlice.reducer;
