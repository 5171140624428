import axiosHttp from "services/axiosHttp";
import { REWARD_BASE_URL } from "configs/AppConfig";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchRewardPricebookTypes = createAsyncThunk("fetchRewardPricebookTypes", async () => {
	const response = await axiosHttp.get(`${REWARD_BASE_URL}common/categories?category_type=price_book`);
	return response.data.data;
});

const RewardPriceBookTypesSlice = createSlice({
	name: "RewardPriceBookTypesSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
	},

	extraReducers: (builder) => {
		builder.addCase(fetchRewardPricebookTypes.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(fetchRewardPricebookTypes.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
		});
		builder.addCase(fetchRewardPricebookTypes.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export default RewardPriceBookTypesSlice.reducer;
