import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { REWARD_BASE_URL } from "configs/AppConfig";
import axiosHttp from "services/axiosHttp";

//CREATE ACTION
export const changeRewardOrderStatus = createAsyncThunk("changeRewardOrderStatus", async ({ id, channel_id, values }, { rejectWithValue }) => {
	try {
		const response = await axiosHttp.put(`${REWARD_BASE_URL}order/${id}/status?channel_id=${channel_id}`, values);

		return response.data;
	} catch (err) {
		if (!err.response) {
			throw err;
		}
		return rejectWithValue(err.response.data);
	}
});

const RewardOrderStatusChangeSlice = createSlice({
	name: "RewardOrderStatusChangeSlice",
	initialState: {
		isLoading: false,
		isError: false,
		error: null,
		data: null,
	},

	extraReducers: (builder) => {
		builder.addCase(changeRewardOrderStatus.pending, (state) => {
			state.isLoading = true;
			state.isError = false;
		});

		builder.addCase(changeRewardOrderStatus.fulfilled, (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.data = action.payload;
		});

		builder.addCase(changeRewardOrderStatus.rejected, (state, action) => {
			state.isLoading = false;
			state.isError = true;
			state.error = action.payload || action.error.message || "Unknown error occurred";
		});
	},
});

export default RewardOrderStatusChangeSlice.reducer;
