import axiosHttp from "services/axiosHttp";
import { REWARD_BASE_URL } from "configs/AppConfig";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Action for updating a tier
export const deleteTier = createAsyncThunk("DeleteTierSlice", async ({ channelId, tierId}, { rejectWithValue }) => {
	try {
		const response = await axiosHttp.delete(`${REWARD_BASE_URL}channel/${channelId}/tier/${tierId}`);
		return response.data;
	} catch (error) {
		// Handle errors
		return rejectWithValue(error.response.data);
	}
});

const DeleteTierSlice = createSlice({
	name: "DeleteTierSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
	},
	reducers: {
		resetDeleteTierSlice: (state) => {
		  // Reset the state to its initial values
		  return  {
				isLoading: false,
				data: null,
				error: null,
			};
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(deleteTier.pending, (state) => {
				state.isLoading = true;
				state.error = null;
			})
			.addCase(deleteTier.fulfilled, (state, action) => {
				state.isLoading = false;
				state.data = action.payload;
			})
			.addCase(deleteTier.rejected, (state, action) => {
				state.isLoading = false;
				state.error = action.payload.message || "An error occurred while updating the tier.";
			});
	},
});

// Export the reset action to use it in components
export const { resetDeleteTierSlice } = DeleteTierSlice.actions;

export default DeleteTierSlice.reducer;
