import { AWS_IMAGE_BUCKET_URL } from "configs/AppConfig";
import axiosHttp from "services/axiosHttp";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchRewardMediaList = createAsyncThunk("fetchRewardOrderList", async ({ page, limit, keyword }, { rejectWithValue }) => {
	const params = new URLSearchParams({
		page: page,
		limit: limit,
		keyword: keyword ?? "",
	});

	try {
		const response = await axiosHttp.get(`${AWS_IMAGE_BUCKET_URL}api/v1/file/list?${params}`);
		return response.data.data;
	} catch (error) {
		return rejectWithValue(error.response?.data?.message || "Something went wrong");
	}
});

const RewardMediaListSlice = createSlice({
	name: "RewardMediaListSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
		page: 1,
		total: 0,
	},

	extraReducers: (builder) => {
		builder.addCase(fetchRewardMediaList.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(fetchRewardMediaList.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
			state.page = action.payload.currentPage;
			state.total = action.payload.total;
		});

		builder.addCase(fetchRewardMediaList.rejected, (state, action) => {
			state.isLoading = false;

			if (action.error.message) {
				state.error = action.error.message;
			} else {
				state.error = "Unknown error occurred";
			}
		});
	},
});

export default RewardMediaListSlice.reducer;
