import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { REWARD_BASE_URL } from "configs/AppConfig";
import axiosHttp from "services/axiosHttp";

//CREATE ACTION
export const removeCategory = createAsyncThunk("updateCategory", async ({ id }, { rejectWithValue }) => {
	try {
		const response = await axiosHttp.delete(`${REWARD_BASE_URL}category/${id}`);
		return response.data;
	} catch (err) {
		if (!err.response) {
			throw err;
		}
		return rejectWithValue(err.response.data);
	}
});

const CategoryRemoveSlice = createSlice({
	name: "CategoryRemoveSlice",
	initialState: {
		isLoading: false,
		isError: false,
		error: null,
		data: null,
	},

	extraReducers: (builder) => {
		builder.addCase(removeCategory.pending, (state, action) => {
			state.isLoading = true;
			state.isError = false;
		});

		builder.addCase(removeCategory.fulfilled, (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.data = action.payload;
		});

		builder.addCase(removeCategory.rejected, (state, action) => {
			state.isLoading = false;
			state.isError = true;
			state.error = action.payload;
		});
	},
});

export default CategoryRemoveSlice.reducer;
