import axiosHttp from "services/axiosHttp";
import { REWARD_BASE_URL } from "configs/AppConfig";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Action for fetching all tiers for a specific channel
export const fetchChannelTiers = createAsyncThunk("fetchChannelTiers", async (channelId, { rejectWithValue }) => {
	try {
		const response = await axiosHttp.get(`${REWARD_BASE_URL}channel/${channelId}/tiers`);
		return response.data.data;
	} catch (error) {
		// Handle errors
		return rejectWithValue(error.response.data);
	}
});

const FetchChannelTiersSlice = createSlice({
	name: "FetchChannelTiersSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
	},

	extraReducers: (builder) => {
		builder
			.addCase(fetchChannelTiers.pending, (state) => {
				state.isLoading = true;
				state.error = null;
			})
			.addCase(fetchChannelTiers.fulfilled, (state, action) => {
				state.isLoading = false;
				state.data = action.payload;
			})
			.addCase(fetchChannelTiers.rejected, (state, action) => {
				state.isLoading = false;
				state.error = action.payload || "An error occurred while fetching tiers for the channel.";
			});
	},
});

export default FetchChannelTiersSlice.reducer;
