import axiosHttp from "services/axiosHttp";
import { REWARD_BASE_URL } from "configs/AppConfig";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";

// Action for fetching inventory summary from the new endpoint
export const fetchProductUpload = createAsyncThunk(
  "fetchProductUpload",
  async ({ channel,file,type }, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append("channel", channel);
      formData.append("type", type);
      formData.append("file", file);
      const response = await axiosHttp.post(`${REWARD_BASE_URL}products/upload`,formData);
      message.success("Inventory uploaded successfully!");
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

const RewardProductUploadSlice = createSlice({
  name: "RewardProductUploadSlice",
  initialState: {
    isLoading: false,
    data: null,
    error: null,
  },

  reducers: {
		resetRewardProductUploadSlice: (state) => {
			state.isLoading = false;
			state.isError = false;
			state.error = null;
			state.data = null;
		},
	},

  extraReducers: (builder) => {
    builder.addCase(fetchProductUpload.pending, (state) => {
      state.isLoading = true;
      state.error = null; // Clear any previous error on new request
    });

    builder.addCase(fetchProductUpload.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload; // Adjust based on response structure
    });

    builder.addCase(fetchProductUpload.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload || "Unknown error occurred";
    });
  },
});


export default RewardProductUploadSlice.reducer;
export const { resetRewardProductUploadSlice } = RewardProductUploadSlice.actions;
