import axiosHttp from "services/axiosHttp";
import { REWARD_BASE_URL } from "configs/AppConfig";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Action for updating a tier
export const updateCashBack = createAsyncThunk("UpdateCashBack", async ({ channelId,data }, { rejectWithValue }) => {
	try {
		const response = await axiosHttp.post(`${REWARD_BASE_URL}channel/${channelId}/cashbacks`, {data:data});
		return response.data;
	} catch (error) {
		// Handle errors
		return rejectWithValue(error.response.data);
	}
});

const UpdateCashBackSlice = createSlice({
	name: "UpdateCashBackSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
	},
	extraReducers: (builder) => {
		builder
			.addCase(updateCashBack.pending, (state) => {
				state.isLoading = true;
				state.error = null;
			})
			.addCase(updateCashBack.fulfilled, (state, action) => {
				state.isLoading = false;
				state.data = action.payload;
			})
			.addCase(updateCashBack.rejected, (state, action) => {
				state.isLoading = false;
				state.error = action.payload || "An error occurred while updating the tier.";
			});
	},
});

export default UpdateCashBackSlice.reducer;
