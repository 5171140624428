import axiosHttp from "services/axiosHttp";
import { REWARD_BASE_URL } from "configs/AppConfig";
import moment from "moment";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

// action
export const fetchRewardExcelExport = createAsyncThunk("fetchRewardExcelExport", async ({ filters, page, limit }, { rejectWithValue }) => {
	let start_date = "";
	let end_date = "";

	if (filters?.range?.length === 2) {
		start_date = moment(filters.range[0].$d).format("YYYY-MM-DD");
		end_date = moment(filters.range[1].$d).format("YYYY-MM-DD");
	}

	const params = new URLSearchParams({
		page: page,
		limit: limit,
		keyword: filters?.keyword ?? "",
		status: filters?.status_id ?? "",
		user_type: filters?.user_type ?? "",
		channel: filters?.channel_uuid,
		date_from: start_date,
		date_to: end_date,
	});

	try {
		const response = await axiosHttp.get(`${REWARD_BASE_URL}order/excel/export?${params}`, {
			responseType: "blob",
		});

		const url = window.URL.createObjectURL(new Blob([response.data]));
		const link = document.createElement("a");
		link.href = url;
		link.setAttribute("download", "reward_export.xlsx");
		document.body.appendChild(link);
		link.click();

		link.parentNode.removeChild(link);
		window.URL.revokeObjectURL(url);

		return { success: true };
	} catch (error) {
		return rejectWithValue(error.response?.data?.message || "Something went wrong");
	}
});

const RewardOrderExportSlice = createSlice({
	name: "RewardOrderExportSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
		page: 1,
		total: 0,
	},

	extraReducers: (builder) => {
		builder.addCase(fetchRewardExcelExport.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(fetchRewardExcelExport.fulfilled, (state) => {
			state.isLoading = false;
		});

		builder.addCase(fetchRewardExcelExport.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload || "Unknown error occurred";
		});
	},
});

export default RewardOrderExportSlice.reducer;
