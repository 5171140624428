import axiosHttp from "services/axiosHttp";
import { REWARD_BASE_URL } from "configs/AppConfig";
import moment from "moment";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchRewardPriceBookList = createAsyncThunk("fetchRewardPriceBookList", async ({ filters, page, limit }, { rejectWithValue }) => {
	let start_date = "";
	let end_date = "";

	if (filters?.range?.length === 2) {
		start_date = moment(filters.range[0].$d).format("YYYY-MM-DD");
		end_date = moment(filters.range[1].$d).format("YYYY-MM-DD");
	}

	const params = new URLSearchParams({
		page: page,
		limit: limit,
		keyword: filters?.keyword ?? "",
		status_id: filters?.status_id ?? "",
		category_id: filters?.category_id ?? "",
		start_date: start_date,
		end_date: end_date,
		channel_uuid: filters?.channel_uuid ?? "",
	});

	try {
		const response = await axiosHttp.get(`${REWARD_BASE_URL}pricebooks?${params}`);
		return response.data.data;
	} catch (error) {
		return rejectWithValue(error.response?.data?.message || "Something went wrong while fetching the price book list");
	}
});

const RewardPriceBookListSlice = createSlice({
	name: "RewardPriceBookListSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
		page: 1,
		total: 0,
	},

	extraReducers: (builder) => {
		builder.addCase(fetchRewardPriceBookList.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(fetchRewardPriceBookList.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
			state.page = action.payload.currentPage || 1;
			state.total = action.payload.total || 0;
		});

		builder.addCase(fetchRewardPriceBookList.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload || action.error.message || "Unknown error occurred";
		});
	},
});

export default RewardPriceBookListSlice.reducer;
