import axiosHttp from "services/axiosHttp";
import { REWARD_BASE_URL } from "configs/AppConfig";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchUserCityList = createAsyncThunk("fetchUserCityList", async () => {
	const response = await axiosHttp.get(`${REWARD_BASE_URL}backoffice/cities`);
	return response.data.data;
});

const RewardUserCityListSlice = createSlice({
	name: "RewardUserCityListSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
		page: 1,
		total: 0,
	},

	extraReducers: (builder) => {
		builder.addCase(fetchUserCityList.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(fetchUserCityList.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
		});
		builder.addCase(fetchUserCityList.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export default RewardUserCityListSlice.reducer;
