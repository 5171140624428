import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { REWARD_BASE_URL } from "configs/AppConfig";
import axiosHttp from "services/axiosHttp";

//CREATE ACTION
export const createRewardPriceBook = createAsyncThunk("createRewardPriceBook", async ({values,channel_uuid}, { rejectWithValue }) => {
	try {
		const response = await axiosHttp.post(`${REWARD_BASE_URL}pricebook?channel_uuid=${channel_uuid}`, values);

		return response.data;
	} catch (err) {
		if (!err.response) {
			throw err;
		}
		return rejectWithValue(err.response.data);
	}
});

const RewardPriceBookCreateSlice = createSlice({
	name: "RewardPriceBookCreateSlice",
	initialState: {
		isLoading: false,
		isError: false,
		error: null,
		data: null,
	},

	reducers: {
		resetCreatePriceBookState: (state) => {
			state.isLoading = false;
			state.isError = false;
			state.error = null;
			state.data = null;
		},
	},

	extraReducers: (builder) => {
		builder.addCase(createRewardPriceBook.pending, (state) => {
			state.isLoading = true;
			state.isError = false;
		});

		builder.addCase(createRewardPriceBook.fulfilled, (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.data = action.payload;
		});

		builder.addCase(createRewardPriceBook.rejected, (state, action) => {
			state.isLoading = false;
			state.isError = true;
			state.error = action.payload;
		});
	},
});

export const { resetCreatePriceBookState } = RewardPriceBookCreateSlice.actions;

export default RewardPriceBookCreateSlice.reducer;
