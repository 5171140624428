import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { REWARD_BASE_URL } from "configs/AppConfig";
import axiosHttp from "services/axiosHttp";

//CREATE ACTION
export const createRewardProduct = createAsyncThunk("createRewardProduct", async (values, { rejectWithValue }) => {
	try {
		const response = await axiosHttp.post(`${REWARD_BASE_URL}product`, values);

		return response.data;
	} catch (err) {
		if (!err.response) {
			throw err;
		}
		return rejectWithValue(err.response.data);
	}
});

const RewardProductCreateSlice = createSlice({
	name: "RewardProductCreateSlice",
	initialState: {
		isLoading: false,
		isError: false,
		error: null,
		data: null,
	},

	reducers: {
		resetCreateProductState: (state) => {
			state.isLoading = false;
			state.isError = false;
			state.error = null;
			state.data = null;
		},
	},

	extraReducers: (builder) => {
		builder.addCase(createRewardProduct.pending, (state) => {
			state.isLoading = true;
			state.isError = false;
		});

		builder.addCase(createRewardProduct.fulfilled, (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.data = action.payload;
		});

		builder.addCase(createRewardProduct.rejected, (state, action) => {
			state.isLoading = false;
			state.isError = true;
			state.error = action.payload;
		});
	},
});

export const { resetCreateProductState } = RewardProductCreateSlice.actions;

export default RewardProductCreateSlice.reducer;
