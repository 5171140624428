import axiosHttp from "services/axiosHttp";
import { REWARD_BASE_URL } from "configs/AppConfig";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchRewardOrderPaymentStatus = createAsyncThunk("fetchRewardOrderPaymentStatus", async () => {
	const response = await axiosHttp.get(`${REWARD_BASE_URL}statuses?keyword=order_payment`);
	return response.data.data;
});

const RewardOrderPaymentStatusSlice = createSlice({
	name: "RewardOrderPaymentStatusSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
	},

	extraReducers: (builder) => {
		builder.addCase(fetchRewardOrderPaymentStatus.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(fetchRewardOrderPaymentStatus.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
		});
		builder.addCase(fetchRewardOrderPaymentStatus.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export default RewardOrderPaymentStatusSlice.reducer;
