import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { REWARD_BASE_URL } from "configs/AppConfig";
import axiosHttp from "services/axiosHttp";

//CREATE ACTION
export const createRewardProductItems = createAsyncThunk("createRewardProductItems", async ({ id, data }, { rejectWithValue }) => {
	try {
		const response = await axiosHttp.post(`${REWARD_BASE_URL}product/${id}/item`, data);

		return response.data;
	} catch (error) {
		if (!error.response) {
			throw error;
		}
		return rejectWithValue(error.response?.data?.message);
	}
});

const RewardProductItemCreateSlice = createSlice({
	name: "RewardProductItemCreateSlice",
	initialState: {
		isLoading: false,
		isError: false,
		error: null,
		data: null,
	},

	reducers: {
		resetCreateProductItemState: (state) => {
			state.isLoading = false;
			state.isError = false;
			state.error = null;
			state.data = null;
		},
	},

	extraReducers: (builder) => {
		builder.addCase(createRewardProductItems.pending, (state) => {
			state.isLoading = true;
			state.isError = false;
		});

		builder.addCase(createRewardProductItems.fulfilled, (state, action) => {
			state.isLoading = false;
			state.isError = false;
			state.data = action.payload;
		});

		builder.addCase(createRewardProductItems.rejected, (state, action) => {
			state.isLoading = false;
			state.isError = true;
			state.error = action.payload;
		});
	},
});

export const { resetCreateProductItemState } = RewardProductItemCreateSlice.actions;

export default RewardProductItemCreateSlice.reducer;
