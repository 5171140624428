import axiosHttp from "services/axiosHttp";
import { REWARD_BASE_URL } from "configs/AppConfig";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Action for fetching inventory summary from the new endpoint
export const fetchInventorySummary = createAsyncThunk(
  "fetchInventorySummary",
  async ({ channel_id }, { rejectWithValue }) => {
    try {
      const response = await axiosHttp.get(`${REWARD_BASE_URL}backoffice/channel/${channel_id}/stock/summary`);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || "Something went wrong");
    }
  }
);

const RewardInventorySummarySlice = createSlice({
  name: "RewardInventorySummarySlice",
  initialState: {
    isLoading: false,
    data: null,
    error: null,
  },

  extraReducers: (builder) => {
    builder.addCase(fetchInventorySummary.pending, (state) => {
      state.isLoading = true;
      state.error = null; // Clear any previous error on new request
    });

    builder.addCase(fetchInventorySummary.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload; // Adjust based on response structure
    });

    builder.addCase(fetchInventorySummary.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload || "Unknown error occurred";
    });
  },
});

export default RewardInventorySummarySlice.reducer;
