import axiosHttp from "services/axiosHttp";
import { REWARD_BASE_URL } from "configs/AppConfig";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

//action
export const fetchRewardUserList = createAsyncThunk("fetchRewardUserList", async ({ filters, page, limit }, { rejectWithValue }) => {
	try {
		const params = new URLSearchParams({
			page: page,
			limit: limit,
			userTypeId: filters?.userTypeId ?? "",
			cityId: filters?.cityId ?? "",
			keyword: filters?.keyword ?? "",
			channel_uuid: filters?.channel_uuid ?? "",
		});

		const response = await axiosHttp.get(`${REWARD_BASE_URL}backOffice/user/list?${params}`);
		return response.data.data;
	} catch (error) {
		return rejectWithValue(error.response?.data?.message || "Something went wrong while fetching the user list");
	}
});

const RewardUserSlice = createSlice({
	name: "RewardUserSlice",
	initialState: {
		isLoading: false,
		data: null,
		error: null,
		page: 1,
		total: 0,
	},

	extraReducers: (builder) => {
		builder.addCase(fetchRewardUserList.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(fetchRewardUserList.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
			state.page = action.payload.current_page || 1;
			state.total = action.payload.total || 0;
		});

		builder.addCase(fetchRewardUserList.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload || action.error.message || "Unknown error occurred";
		});
	},
});

export default RewardUserSlice.reducer;
